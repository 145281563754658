body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
blockquote {
}
.finalScore{
	color: blue;
	background-color: white !important;

}
.aboutPage{
	text-align: left;
}

.response, .response:hover {
	text-align: left;
	padding-left: 30px;
	padding-bottom: 15px !important;

}

.quizCard {
	position: relative;
	background-color: #f3f2eb;
	border-radius: 6px;
	box-shadow: 1px 1px 1px 2px #959da2;
	height: 70px;
	width: 400px;
	margin: 10px 15px;
	text-align: center;
}

.quizPlayCard {
	position: relative;
	background-color: #f3f2eb;
	border-radius: 6px;
	box-shadow: 1px 1px 1px 2px #959da2;
	width: 98% !important;
	margin-bottom: 0px !important;
	text-align: center;
	padding: 0;
}

.par1 {
	background-color: #88a187;
	border-radius: 0 0 0 6px;
	position: absolute;
	width: 200px;
	height: 40px;
	top: 40px;
	bottom: 40px;
	left: 0;
	font-size: 16px !important;
	font-weight: lighter;
	text-align: center;

}

.par2{
	background-color: #88a187;
	border-radius: 0 0 0 6px;
	position: absolute;
	width: 200px;
	height: 40px;
	top: 40px;
	bottom: 40px;
	right: -200;
	font-size: 16px !important;
	font-weight: lighter;
	text-align: center;
}

.TitleCard {
    font-size: 16px !important;
	color: #000;
	border: 0 solid #f3f2eb;
	border-radius: 6px 6px 0 0;
	margin-top: 0;
	width: 100%;
	position: absolute;
	height: 40px;
	font-family: Mali-Medium;
	text-shadow: 4px 4px 4px #aaa;
	background-color: #f3f2eb;
	word-wrap: break-word;
	padding-top: 15px !important;

}

/*quiz title*/
.qListTitleCard {
	font-size: 15px !important;
	color: darkblue !important;
	border: 0 solid #f3f2eb !important;
	border-radius: 6px 6px 0 0 !important;
	margin-top: 0 !important;
	width: 100% !important;
	position: absolute !important;
	height: 40px !important;
	font-family: Mali-Medium !important;
	text-shadow: 4px 4px 4px #aaa !important;
	background-color: #f3f2eb !important;
	word-wrap: break-word !important;
	padding-top: 15px !important;
	text-align: left !important;
	padding-left: 10px;
}


/*quiz score*/
.scoreDone {
    font-size: 14px !important;
    background-color: #f3f2eb !important;
}

/*this quiz has 10 questions*/
.quiznbr {
	color: #000;
	font-style: italic;
	font-weight: 400;
    font-size: 14px !important;
}


.bibleReference {
    font-size: 18px !important;
	font-style: italic;
}

.myquiztitleQ {
    font-size: 18px !important;
}

.tooltiptext {
	visibility: hidden;
	width: 160px !important;
	background-color: #000;
	color: #fff;
	padding: 10px !important;
	border-radius: 6px;
	font-size: 15px !important;
	top: 35px !important;
	position: absolute;
	z-index: 1;
	left: 10px !important;
}


.HomeBase .lander p {
	color: green;
}

#logincomment {
	color: darkblue;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
  }
  

.HomeBase .lander {
    padding: 40px 0;
    text-align: center;
  }
  
  .HomeBase .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .HomeBase .lander p {
    color: #999;
  }
  .quizCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    height: 150px;
    width: 300px;
    margin:10px 15px;
    text-align: center;
}
.quizCard:hover {
  box-shadow: 1px 2px 6px 2px #F3F2EB;

}


.TitleCard {
  font-size: 25px;
  color: black;
  border: 0px solid #F3F2EB;
  border-radius: 6px 6px 0px 0px;
  margin-top: 0px;
  width: 100%;
  position: absolute;
  height: 70px;
  font-family: Mali-Medium;
  text-shadow: 4px 4px 4px #aaa;
  background-color: #F3F2EB;
  word-wrap: break-word;
}
.myCardFlexContainer{
  margin: 20px;
}
.par1 {
  background-color: #88A187;
  position: absolute;
  top: 70px;
  height: 80px;
  width: 150px;
  border-radius: 0px 0px 0px 6px;
  left: 0px;
}
.par2 {
  background-color: #448D76;
  position: absolute;
  top: 70px;
  height: 80px;
  width: 150px;
  left:150px;
  border-radius: 0px 0px 6px 0px;
}
.par1:hover {
  box-shadow: 1px 2px 3px 1px #747265;
}
.par2:hover {
  box-shadow: 1px 2px 3px 1px #3B3E2F;
}

.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.myLinks {
  text-decoration: none;
  color: black;
}
.mySubLinks {
  text-decoration: none;
  color: black;
}
.goRight {
  position:absolute;
  right: 10px;
  top:4px;
  display: inline-flex;
}
.mytoolbar {
    min-height: 34px !important;
}
.goLeft {
    position:absolute;
  left: 10px;
  top:4px;
  display: inline-flex;
}
.btn {
  margin:4px; 
  padding-top:1px !important;
  padding-bottom:1px !important;
}
.forceInline {
  display: inline-flex;
}
.phonetoolbar {
	display: none;
	font-size: 15px;
}
.fstoolbar {
	display: block;
	font-size: 15px;
}
.box-shadow-menu {
  position: relative;
  padding-left: 1.25em;
}

.box-shadow-menu:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.25em;
  width: 1em;
  height: 0.15em;
  background: black;
  box-shadow: 
    0 0.25em 0 0 black,
    0 0.5em 0 0 black;
}
.myExpa{
	width: 200px;
}
.SubMenu {
	opacity: 0.87;
	background-color: white;
	margin-top: 8px
}
.pageon {
  color: #0000AA !important;
}
.pageoff {
  color:#555555 !important;
}
.subHover {

}

.subHover:hover .SubMenu{
  display: block;
}

.subHover .SubMenu{
  display: none;
}







@media only screen and (max-width: 800px) {
  .respBtn {
    padding: 4px;
    font-size: 20px;
  }
  .fstoolbar {
  	display: none;
  		font-size: 15px;
  }
  .phonetoolbar {
  	display: block;
  		font-size: 15px;
      min-height: 34px !important;
  }
  .MenuOpen {

  }
  .MenuClose {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
  }
  .MenuList {
  	width: 200px;
  }
  .MenuDiv {
  	width: 200px;
  }

  .subHover:hover .SubMenu{
  display: block;
}

.subHover .SubMenu{
  display: block;
}
.SubMenu {
    opacity: 0.87;
  background-color: #F5F5F5;
  margin-top: 0px;
  margin-left:10px;
}
}
.cardOpacity{
	opacity: 0.8;
}
.actTab{
	border-bottom: 1px solid red !important;
}
.registerText{
	color: black;
	font-size: 17px;
	padding: 10px;
	max-width: 400px;
}
.PaperOpacity{
	margin-top: 100px;
	opacity: 0.8;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.userTab {
}
.studTab {
}
.tab1 {
	color: black  !important;
	background-color: #FFFF00  !important;
}
.tab2 {
	color: black  !important;
	background-color: #FFFF66  !important;
}
#signupbt {
	  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 10px;
  border-color: green;
}

.snackbar .MuiPaper-root {
  background-color: black !important;
  font-size: 20px;
  border:1px solid white;
}
#cancelbt {
  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 20px;
  border-color: red;
}
.cardOpacity{
	opacity: 0.8;
}
.actTab{
	border-bottom: 1px solid red !important;
}
.registerText{
	color: black;
	font-size: 17px;
	padding: 10px;
	max-width: 400px;
}
.PaperOpacity{
	margin-top: 100px;
	opacity: 0.8;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.userTab {
}
.studTab {
}
.tab1 {
	color: black  !important;
	background-color: #FFFF00  !important;
}
.tab2 {
	color: black  !important;
	background-color: #FFFF66  !important;
}
textarea {
	min-width: 500px;
}

#outlined-multiline-static {
	min-width: 500px;
}
.LoginPage {
	
}
.cardOpacity{
	opacity: 0.8;
}
.PaperOpacity{
	margin-top: 100px;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.btnClass {
	color: black;
}
#loginbt{
  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 10px;
  border-color: green;
}

.snackbar .MuiPaper-root {
  background-color: black !important;
  font-size: 20px;
  border:1px solid white;
}
#registerbt {
  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 10px;
  border-color: blue;
}
#cancelbt {
  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 20px;
  border-color: red;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.About {
	margin-top: 60px;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.About {
	margin-top: 60px;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.About {
	margin-top: 60px;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.About {
	margin-top: 60px;
}
.HomeBase .lander {
    padding: 40px 0;
    text-align: center;
  }
  
  .HomeBase .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .HomeBase .lander p {
    color: #999;
  }
  .quizCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    height: 80px;
    width: 300px;
    margin: 10px 15px;
    text-align: center;
}
.quizCard:hover {
  box-shadow: 1px 2px 6px 2px #F3F2EB;
}


.TitleCard {
  font-size: 25px;
  color: black;
  border: 0px solid #F3F2EB;
  border-radius: 6px 6px 0px 0px;
  margin-top: 0px;
  width: 100%;
  position: absolute;
  height: 40px;
  font-family: Mali-Medium;
  text-shadow: 4px 4px 4px #aaa;
  background-color: #F3F2EB;
  word-wrap: break-word;
  padding-top: 10px;
}
.myCardFlexContainer{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 20px;
}
.par1 {
  background-color: #88A187;
  position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  border-radius: 0px 0px 0px 6px;
  left: 0px;
  font-size: 20px;
}
.par2 {
  background-color: #448D76;
  position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  left:150px;
  border-radius: 0px 0px 6px 0px;
  font-size: 20px;
}
.par1:hover {
  box-shadow: 1px 2px 3px 1px #747265;
}
.par2:hover {
  box-shadow: 1px 2px 3px 1px #3B3E2F;
}
@media only screen and (max-width: 600px) {
  .par1 {
  font-size: 15px;
}
.par2 {
  font-size: 15px;
}
}
.myQuizguestCardFlexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 20px;
    justify-content: center;
}
.centerSpan {
	
}
.forceInlineandleft {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  margin-left: 20px;
  flex-wrap: wrap;
}
.myNavLinks {
	font-size: 20px;
	text-decoration: none;
	padding-top: 20px;
}
.active {
	color: red;
}
.myNavLinksspace {
	font-size: 20px;
	padding-top: 20px;
}
.GuestQuizListContainer {
  display: flex;
margin-top: 32px;
}
.myCardFlexContainer {
	  align-items: center;
  justify-content: center;
}
  .gquizListCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    height: 50px;
    width: 300px;
    margin: 10px;
    text-align: center;
}
.gquizListCard:hover {
  box-shadow: 1px 2px 6px 2px #F3F2EB;
}
.quizListCardtitle {

}
.qListTitleCard {
	  font-size: 25px;
  color: black;
  border: 0px solid #F3F2EB;
  border-radius: 6px 6px 0px 0px;
  margin-top: 0px;
  width: 100%;
  position: absolute;
  height: 40px;
  font-family: Mali-Medium;
  text-shadow: 4px 4px 4px #aaa;
  background-color: #F3F2EB;
  word-wrap: break-word;
  padding-top: 10px;
}
.quizDone {
  background-color: red;
}
.scoreDone {
  height: 50px;
  width: 50px;
  font-size: 15px;
  border-radius: 0px 6px 0px 0px;
  background-color: blue;
  position: absolute;
  top:0px;
  right: 0px;
}
.tooltiptext {
    visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 20px;
 top: 50px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
.quizDone:hover .tooltiptext {
  visibility: visible;
}
.myQuizguestCardFlexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 20px;
    justify-content: center;
}
.centerSpan {
  
}
.myPlayFlexContainer {
	display: flex;
	justify-content: center;
}
.myResFlexContainer {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.quizPlayCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    width: 90%;
    margin-bottom: 10px;
    text-align: center;
    padding: 0px;
}
.TitlePlayCard {
    color: black;
    border: 0px solid #F3F2EB;
    border-radius: 6px 6px 0px 0px;
    margin: 0px;
    margin-bottom: 10px;
    width: 100%;
    font-family: Mali-Medium;
    text-shadow: 4px 4px 4px #aaa;
    background-color: #F3F2EB;
    word-wrap: break-word;
    font-size: 40px;
    text-align: left;
}

.TitlePlayCardres {
    color: black;
    border: 0px solid #F3F2EB;
    border-radius: 6px 6px 0px 0px;
    margin: 0px;
    margin-bottom: 10px;
    width: 100%;
    font-family: Mali-Medium;
    background-color: #F3F2EB;
    word-wrap: break-word;
    text-align: left;    
    font-weight: 400;
}
.myquiztitle {
    padding:30px;
    padding-left: 30px;
    color: #0000CC;
}
.myquiztitleQ{
    font-size: 25px;
}
.bibleReference{
    font-size: 25px;
    font-style: italic;
}
.response {
	color: #222222;
	font-size: 36px;
    text-align: left;
    padding-left: 30px;
}
.response:hover {
	color: #999999;
	background-color: #222222;
	border:0px solid #F3F2EB;
    text-align: left;
    padding-left: 30px;
}
.selected{
	color: #CCCCCC;
	background-color: #111111;
	border:0px solid #F3F2EB;
	font-size: 36px;
    text-align: center;
    padding-left: 30px;
}
.goodA {
	color: #FFFFFF;
	background-color: #00FF00;
}
.falseA {
		color: #FFFFFF;
	background-color: #FF0000;
}
.goodAB {
	color: #111111;
}
.falseAB {
		color: #111111;
}
.quizResCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    width: 70%;
    margin: auto;
    margin-top: 30px;
    text-align: center;
}
.QuizBase {
    margin-top: 32px;
}
.lander {
}
.quiztitle {
    padding-top: 10px;
}
.MuiButton-root-73{
    padding: 16px 16px;
}
.rowRes {
    padding-bottom: 10px;
}
.row {
    padding-bottom: 50px;
}

.quiznbr {
    color: black;
    font-style: italic;
    font-weight: 400;
}
@media only screen and (max-width: 800px) {

    .quizPlayCard {
        position: relative;
        background-color: #F3F2EB;
        border-radius: 6px;
        box-shadow: 1px 1px 1px 2px #959DA2;
        width: 90%;
        margin-bottom: 20px;
        text-align: center;
        padding: 0px;
    }
    .TitlePlayCard {
        color: black;
        border: 0px solid #F3F2EB;
        border-radius: 6px 6px 0px 0px;
        margin: 0px;
        margin-bottom: 10px;
        width: 100%;
        font-family: Mali-Medium;
        text-shadow: 4px 4px 4px #aaa;
        background-color: #F3F2EB;
        word-wrap: break-word;
        font-size: 24px;
        text-align: left;
    }

    .TitlePlayCardres {
        color: black;
        border: 0px solid #F3F2EB;
        border-radius: 6px 6px 0px 0px;
        margin: 0px;
        margin-bottom: 10px;
        width: 100%;
        font-family: Mali-Medium;
        background-color: #F3F2EB;
        word-wrap: break-word;
        font-size: 24px;
        text-align: left;    
        font-weight: 400;
    }
    .myquiztitle {
        padding:15px;
        padding-left: 10px;
        color: #0000CC;
    }
    .response {
        color: #222222;
        font-size: 24px;
        text-align: left;
        padding-left: 10px;
    }
    .response:hover {
        color: #999999;
        background-color: #222222;
        border:0px solid #F3F2EB;
        text-align: left;
        padding-left: 10px;
    }
    .selected{
        color: #CCCCCC;
        background-color: #111111;
        border:0px solid #F3F2EB;
        font-size: 24px;
        text-align: center;
        padding-left: 10px;
    }
    .goodA {
        color: #FFFFFF;
        background-color: #00FF00;
        font-size: 20px;
    }
    .falseA {
            color: #FFFFFF;
        background-color: #FF0000;
        font-size: 20px;
    }
    .goodAB {
        color: #111111;
        font-size: 20px;
    }
    .falseAB {
            color: #111111;
        font-size: 20px;
    }
}
.myQuizguestCardFlexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 20px;
    justify-content: center;
}
.centerSpan {
	
}
.forceInlineandleft {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  margin-left: 20px;
  flex-wrap: wrap;
}
.myNavLinks {
	font-size: 20px;
	text-decoration: none;
	padding-top: 20px;
}
.active {
	color: red;
}
.myNavLinksspace {
	font-size: 20px;
	padding-top: 20px;
}
  
.progressBar {
	position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  left: 0px;
	text-align: left;
	border-radius: 0px 0px 0px 6px;
	opacity: 0.5;
}
.progressBarper{

	height: 100%;
	border-radius: 0px 0px 0px 6px;
}
.myQuizguestCardFlexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 20px;
    justify-content: center;
}
.centerSpan {
	
}
.forceInlineandleft {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  margin-left: 20px;
  flex-wrap: wrap;
}
.myNavLinks {
	font-size: 20px;
	text-decoration: none;
	padding-top: 20px;
}
.active {
	color: red;
}
.myNavLinksspace {
	font-size: 20px;
	padding-top: 20px;
}
.profilPage {

}
.profilPageHist {
  width: 400px;
}
  .quizCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    height: 80px;
    width: 300px;
    margin: 10px 15px;
    text-align: center;
}
.quizCard:hover {
  box-shadow: 1px 2px 6px 2px #F3F2EB;
}

.TitleCardprofil {
  font-size: 20px;
  color: black;
  border: 0px solid #F3F2EB;
  border-radius: 6px 6px 0px 0px;
  margin-top: 0px;
  width: 100%;
  position: absolute;
  height: 40px;
  font-family: Mali-Medium;
  text-shadow: 2px 2px 2px #aaa;
  background-color: #F3F2EB;
  word-wrap: break-word;
  padding-top: 10px;
}
.myCardFlexContainer{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 20px;
}
.par1profil {
  background-color: #88A187;
  opacity: 0.7;
  position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  border-radius: 0px 0px 0px 6px;
  left: 0px;
  font-size: 20px;
}
.par2profil {
  background-color: #448D76;
  position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  left:150px;
  border-radius: 0px 0px 6px 0px;
  font-size: 14px;
}
.par1profil:hover {
  box-shadow: 1px 2px 3px 1px #747265;
}
.par2profil:hover {
  box-shadow: 1px 2px 3px 1px #3B3E2F;
}
@media only screen and (max-width: 600px) {
  .par1profil {
  font-size: 15px;
}
.par2profil {
  font-size: 15px;
}
.centerprofil {
margin-top: auto;
    padding-top: 10px;
}
}
.active {
	color:black !important;
	background-color:transparent;
}
.myFlexContainer {
	display:flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	margin-top: 40px;
	margin-bottom: 80px;
	background-color: #000000;
	opacity: 0.7;
}
.myFlexItem {
	margin: 10px;
}
.bCrumb{
	margin-top: 30px;
}
.verse p {
    font-size: 10px;
    color:#CCEEFF;
}
#leftMenu {
    height: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
}
#BibleMenu {
	padding-top: 20px;
}
#BibleMenuRight {
    float: right;
}
.myGrid {
    display: grid !important;
    grid-template-columns: 250px 1fr;
}
.myContain {
        grid-column-start: 2;
    grid-column-end: 3;
}
.bookCustomScroll {
	height:  100vh;
	flex-basis: 150px;
}
.bookCustomScroll2 {
	height:  100vh;
	flex-basis: 70px;
}
.myNotes {
	height:  100vh;
	flex-basis: 250px;
}
.myFlexReader {
	text-align: Left;
	padding-left: 30px;
}


.searchroot {
    padding: '2px 4px';
    display: 'flex';
    text-align: center;
    width: 100;
}
.searchinput {
	margin-left: 8px;
    flex: 1 1;
}
.searchiconButton {
	padding: 10px;
}
.searchdivider {
	width: 1px;
    height: 28px;
    margin: 4px;
}
.bibleBooks {
	color:white !important;
	min-width: 150px;
}

.bibleChapter {
	color:white !important;
	min-width: 50px;
}

.bibleVerse {
	color:white !important;
}
.bibleBooks:hover {
	color:white !important;
	min-width: 150px;
	padding: 2px;
	border:1px solid gold;
	cursor: pointer;
}

.bibleChapter:hover {
	color:white !important;
	min-width: 50px;
	padding: 10px;
	border:1px solid gold;
	cursor: pointer;
}

.bibleVerse:hover {
	color:gold !important;
	cursor: pointer;
}
.highl {
	background-color: teal;
}
@media only screen and (max-width: 800px) {
	#BibleMenu {
		margin-top: 30px;
	}
}
#bookselect{
	font-size: 25px;
}
#chapterselect{
	font-size: 25px;
}
#divBible {
	max-width: 800px;
}
#btPrev {
	position: fixed;
	margin-top: 50vh;
}
#btNext {
	position: fixed;
	margin-top: 50vh;
}
#divbtPrev {
	padding-right: 50px;
}
#divbtNext {
	padding-right: 50px;
}
#mySearchRoot {
	width: 100%;
	
}
#mySearchPaper {
	background-color: black;
}
#mySearchInput{
	color:#ffa000;
	font-size: 20px;
}
.verseResult {
	color:#fff8e1 !important;
	cursor: pointer;
}
.LoginPage {
	
}
.cardOpacity{
	opacity: 0.8;
}
.PaperOpacity{
	margin-top: 100px;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.rcw-widget-container{
	margin-top: 50px;
top: 1px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
    background-image:url("https://easyjobnetdemo.be/playBibleQuiz/assets/image/openbible1.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
   background-attachment: fixed;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

