body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
blockquote {
}
.finalScore{
	color: blue;
	background-color: white !important;

}
.aboutPage{
	text-align: left;
}

.response, .response:hover {
	text-align: left;
	padding-left: 30px;
	padding-bottom: 15px !important;

}

.quizCard {
	position: relative;
	background-color: #f3f2eb;
	border-radius: 6px;
	box-shadow: 1px 1px 1px 2px #959da2;
	height: 70px;
	width: 400px;
	margin: 10px 15px;
	text-align: center;
}

.quizPlayCard {
	position: relative;
	background-color: #f3f2eb;
	border-radius: 6px;
	box-shadow: 1px 1px 1px 2px #959da2;
	width: 98% !important;
	margin-bottom: 0px !important;
	text-align: center;
	padding: 0;
}

.par1 {
	background-color: #88a187;
	border-radius: 0 0 0 6px;
	position: absolute;
	width: 200px;
	height: 40px;
	top: 40px;
	bottom: 40px;
	left: 0;
	font-size: 16px !important;
	font-weight: lighter;
	text-align: center;

}

.par2{
	background-color: #88a187;
	border-radius: 0 0 0 6px;
	position: absolute;
	width: 200px;
	height: 40px;
	top: 40px;
	bottom: 40px;
	right: -200;
	font-size: 16px !important;
	font-weight: lighter;
	text-align: center;
}

.TitleCard {
    font-size: 16px !important;
	color: #000;
	border: 0 solid #f3f2eb;
	border-radius: 6px 6px 0 0;
	margin-top: 0;
	width: 100%;
	position: absolute;
	height: 40px;
	font-family: Mali-Medium;
	text-shadow: 4px 4px 4px #aaa;
	background-color: #f3f2eb;
	word-wrap: break-word;
	padding-top: 15px !important;

}

/*quiz title*/
.qListTitleCard {
	font-size: 15px !important;
	color: darkblue !important;
	border: 0 solid #f3f2eb !important;
	border-radius: 6px 6px 0 0 !important;
	margin-top: 0 !important;
	width: 100% !important;
	position: absolute !important;
	height: 40px !important;
	font-family: Mali-Medium !important;
	text-shadow: 4px 4px 4px #aaa !important;
	background-color: #f3f2eb !important;
	word-wrap: break-word !important;
	padding-top: 15px !important;
	text-align: left !important;
	padding-left: 10px;
}


/*quiz score*/
.scoreDone {
    font-size: 14px !important;
    background-color: #f3f2eb !important;
}

/*this quiz has 10 questions*/
.quiznbr {
	color: #000;
	font-style: italic;
	font-weight: 400;
    font-size: 14px !important;
}


.bibleReference {
    font-size: 18px !important;
	font-style: italic;
}

.myquiztitleQ {
    font-size: 18px !important;
}

.tooltiptext {
	visibility: hidden;
	width: 160px !important;
	background-color: #000;
	color: #fff;
	padding: 10px !important;
	border-radius: 6px;
	font-size: 15px !important;
	top: 35px !important;
	position: absolute;
	z-index: 1;
	left: 10px !important;
}


.HomeBase .lander p {
	color: green;
}

#logincomment {
	color: darkblue;
}