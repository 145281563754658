.cardOpacity{
	opacity: 0.8;
}
.actTab{
	border-bottom: 1px solid red !important;
}
.registerText{
	color: black;
	font-size: 17px;
	padding: 10px;
	max-width: 400px;
}
.PaperOpacity{
	margin-top: 100px;
	opacity: 0.8;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.userTab {
}
.studTab {
}
.tab1 {
	color: black  !important;
	background-color: #FFFF00  !important;
}
.tab2 {
	color: black  !important;
	background-color: #FFFF66  !important;
}
#signupbt {
	  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 10px;
  border-color: green;
}

.snackbar .MuiPaper-root {
  background-color: black !important;
  font-size: 20px;
  border:1px solid white;
}
#cancelbt {
  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 20px;
  border-color: red;
}