.HomeBase .lander {
    padding: 40px 0;
    text-align: center;
  }
  
  .HomeBase .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .HomeBase .lander p {
    color: #999;
  }
  .quizCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    height: 80px;
    width: 300px;
    margin: 10px 15px;
    text-align: center;
}
.quizCard:hover {
  box-shadow: 1px 2px 6px 2px #F3F2EB;
}


.TitleCard {
  font-size: 25px;
  color: black;
  border: 0px solid #F3F2EB;
  border-radius: 6px 6px 0px 0px;
  margin-top: 0px;
  width: 100%;
  position: absolute;
  height: 40px;
  font-family: Mali-Medium;
  text-shadow: 4px 4px 4px #aaa;
  background-color: #F3F2EB;
  word-wrap: break-word;
  padding-top: 10px;
}
.myCardFlexContainer{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 20px;
}
.par1 {
  background-color: #88A187;
  position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  border-radius: 0px 0px 0px 6px;
  left: 0px;
  font-size: 20px;
}
.par2 {
  background-color: #448D76;
  position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  left:150px;
  border-radius: 0px 0px 6px 0px;
  font-size: 20px;
}
.par1:hover {
  box-shadow: 1px 2px 3px 1px #747265;
}
.par2:hover {
  box-shadow: 1px 2px 3px 1px #3B3E2F;
}
@media only screen and (max-width: 600px) {
  .par1 {
  font-size: 15px;
}
.par2 {
  font-size: 15px;
}
}