.myPlayFlexContainer {
	display: flex;
	justify-content: center;
}
.myResFlexContainer {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.quizPlayCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    width: 90%;
    margin-bottom: 10px;
    text-align: center;
    padding: 0px;
}
.TitlePlayCard {
    color: black;
    border: 0px solid #F3F2EB;
    border-radius: 6px 6px 0px 0px;
    margin: 0px;
    margin-bottom: 10px;
    width: 100%;
    font-family: Mali-Medium;
    text-shadow: 4px 4px 4px #aaa;
    background-color: #F3F2EB;
    word-wrap: break-word;
    font-size: 40px;
    text-align: left;
}

.TitlePlayCardres {
    color: black;
    border: 0px solid #F3F2EB;
    border-radius: 6px 6px 0px 0px;
    margin: 0px;
    margin-bottom: 10px;
    width: 100%;
    font-family: Mali-Medium;
    background-color: #F3F2EB;
    word-wrap: break-word;
    text-align: left;    
    font-weight: 400;
}
.myquiztitle {
    padding:30px;
    padding-left: 30px;
    color: #0000CC;
}
.myquiztitleQ{
    font-size: 25px;
}
.bibleReference{
    font-size: 25px;
    font-style: italic;
}
.response {
	color: #222222;
	font-size: 36px;
    text-align: left;
    padding-left: 30px;
}
.response:hover {
	color: #999999;
	background-color: #222222;
	border:0px solid #F3F2EB;
    text-align: left;
    padding-left: 30px;
}
.selected{
	color: #CCCCCC;
	background-color: #111111;
	border:0px solid #F3F2EB;
	font-size: 36px;
    text-align: center;
    padding-left: 30px;
}
.goodA {
	color: #FFFFFF;
	background-color: #00FF00;
}
.falseA {
		color: #FFFFFF;
	background-color: #FF0000;
}
.goodAB {
	color: #111111;
}
.falseAB {
		color: #111111;
}
.quizResCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    width: 70%;
    margin: auto;
    margin-top: 30px;
    text-align: center;
}
.QuizBase {
    margin-top: 32px;
}
.lander {
}
.quiztitle {
    padding-top: 10px;
}
.MuiButton-root-73{
    padding: 16px 16px;
}
.rowRes {
    padding-bottom: 10px;
}
.row {
    padding-bottom: 50px;
}

.quiznbr {
    color: black;
    font-style: italic;
    font-weight: 400;
}
@media only screen and (max-width: 800px) {

    .quizPlayCard {
        position: relative;
        background-color: #F3F2EB;
        border-radius: 6px;
        box-shadow: 1px 1px 1px 2px #959DA2;
        width: 90%;
        margin-bottom: 20px;
        text-align: center;
        padding: 0px;
    }
    .TitlePlayCard {
        color: black;
        border: 0px solid #F3F2EB;
        border-radius: 6px 6px 0px 0px;
        margin: 0px;
        margin-bottom: 10px;
        width: 100%;
        font-family: Mali-Medium;
        text-shadow: 4px 4px 4px #aaa;
        background-color: #F3F2EB;
        word-wrap: break-word;
        font-size: 24px;
        text-align: left;
    }

    .TitlePlayCardres {
        color: black;
        border: 0px solid #F3F2EB;
        border-radius: 6px 6px 0px 0px;
        margin: 0px;
        margin-bottom: 10px;
        width: 100%;
        font-family: Mali-Medium;
        background-color: #F3F2EB;
        word-wrap: break-word;
        font-size: 24px;
        text-align: left;    
        font-weight: 400;
    }
    .myquiztitle {
        padding:15px;
        padding-left: 10px;
        color: #0000CC;
    }
    .response {
        color: #222222;
        font-size: 24px;
        text-align: left;
        padding-left: 10px;
    }
    .response:hover {
        color: #999999;
        background-color: #222222;
        border:0px solid #F3F2EB;
        text-align: left;
        padding-left: 10px;
    }
    .selected{
        color: #CCCCCC;
        background-color: #111111;
        border:0px solid #F3F2EB;
        font-size: 24px;
        text-align: center;
        padding-left: 10px;
    }
    .goodA {
        color: #FFFFFF;
        background-color: #00FF00;
        font-size: 20px;
    }
    .falseA {
            color: #FFFFFF;
        background-color: #FF0000;
        font-size: 20px;
    }
    .goodAB {
        color: #111111;
        font-size: 20px;
    }
    .falseAB {
            color: #111111;
        font-size: 20px;
    }
}