.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.About {
	margin-top: 60px;
}