.myQuizguestCardFlexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 20px;
    justify-content: center;
}
.centerSpan {
	
}
.forceInlineandleft {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  margin-left: 20px;
  flex-wrap: wrap;
}
.myNavLinks {
	font-size: 20px;
	text-decoration: none;
	padding-top: 20px;
}
.active {
	color: red;
}
.myNavLinksspace {
	font-size: 20px;
	padding-top: 20px;
}
.profilPage {

}
.profilPageHist {
  width: 400px;
}
  .quizCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    height: 80px;
    width: 300px;
    margin: 10px 15px;
    text-align: center;
}
.quizCard:hover {
  box-shadow: 1px 2px 6px 2px #F3F2EB;
}

.TitleCardprofil {
  font-size: 20px;
  color: black;
  border: 0px solid #F3F2EB;
  border-radius: 6px 6px 0px 0px;
  margin-top: 0px;
  width: 100%;
  position: absolute;
  height: 40px;
  font-family: Mali-Medium;
  text-shadow: 2px 2px 2px #aaa;
  background-color: #F3F2EB;
  word-wrap: break-word;
  padding-top: 10px;
}
.myCardFlexContainer{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 20px;
}
.par1profil {
  background-color: #88A187;
  opacity: 0.7;
  position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  border-radius: 0px 0px 0px 6px;
  left: 0px;
  font-size: 20px;
}
.par2profil {
  background-color: #448D76;
  position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  left:150px;
  border-radius: 0px 0px 6px 0px;
  font-size: 14px;
}
.par1profil:hover {
  box-shadow: 1px 2px 3px 1px #747265;
}
.par2profil:hover {
  box-shadow: 1px 2px 3px 1px #3B3E2F;
}
@media only screen and (max-width: 600px) {
  .par1profil {
  font-size: 15px;
}
.par2profil {
  font-size: 15px;
}
.centerprofil {
margin-top: auto;
    padding-top: 10px;
}
}