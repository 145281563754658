  
.progressBar {
	position: absolute;
  top: 40px;
  height: 40px;
  width: 150px;
  left: 0px;
	text-align: left;
	border-radius: 0px 0px 0px 6px;
	opacity: 0.5;
}
.progressBarper{

	height: 100%;
	border-radius: 0px 0px 0px 6px;
}