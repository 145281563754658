.myLinks {
  text-decoration: none;
  color: black;
}
.mySubLinks {
  text-decoration: none;
  color: black;
}
.goRight {
  position:absolute;
  right: 10px;
  top:4px;
  display: inline-flex;
}
.mytoolbar {
    min-height: 34px !important;
}
.goLeft {
    position:absolute;
  left: 10px;
  top:4px;
  display: inline-flex;
}
.btn {
  margin:4px; 
  padding-top:1px !important;
  padding-bottom:1px !important;
}
.forceInline {
  display: inline-flex;
}
.phonetoolbar {
	display: none;
	font-size: 15px;
}
.fstoolbar {
	display: block;
	font-size: 15px;
}
.box-shadow-menu {
  position: relative;
  padding-left: 1.25em;
}

.box-shadow-menu:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.25em;
  width: 1em;
  height: 0.15em;
  background: black;
  box-shadow: 
    0 0.25em 0 0 black,
    0 0.5em 0 0 black;
}
.myExpa{
	width: 200px;
}
.SubMenu {
	opacity: 0.87;
	background-color: white;
	margin-top: 8px
}
.pageon {
  color: #0000AA !important;
}
.pageoff {
  color:#555555 !important;
}
.subHover {

}

.subHover:hover .SubMenu{
  display: block;
}

.subHover .SubMenu{
  display: none;
}







@media only screen and (max-width: 800px) {
  .respBtn {
    padding: 4px;
    font-size: 20px;
  }
  .fstoolbar {
  	display: none;
  		font-size: 15px;
  }
  .phonetoolbar {
  	display: block;
  		font-size: 15px;
      min-height: 34px !important;
  }
  .MenuOpen {

  }
  .MenuClose {
      transform: rotate(360deg);
  }
  .MenuList {
  	width: 200px;
  }
  .MenuDiv {
  	width: 200px;
  }

  .subHover:hover .SubMenu{
  display: block;
}

.subHover .SubMenu{
  display: block;
}
.SubMenu {
    opacity: 0.87;
  background-color: #F5F5F5;
  margin-top: 0px;
  margin-left:10px;
}
}