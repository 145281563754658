.LoginPage {
	
}
.cardOpacity{
	opacity: 0.8;
}
.PaperOpacity{
	margin-top: 100px;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}
.btnClass {
	color: black;
}
#loginbt{
  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 10px;
  border-color: green;
}

.snackbar .MuiPaper-root {
  background-color: black !important;
  font-size: 20px;
  border:1px solid white;
}
#registerbt {
  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 10px;
  border-color: blue;
}
#cancelbt {
  font-size: 15px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 20px;
  border-color: red;
}