.GuestQuizListContainer {
  display: flex;
margin-top: 32px;
}
.myCardFlexContainer {
	  align-items: center;
  justify-content: center;
}
  .gquizListCard {
    position: relative;
    background-color: #F3F2EB;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 2px #959DA2;
    height: 50px;
    width: 300px;
    margin: 10px;
    text-align: center;
}
.gquizListCard:hover {
  box-shadow: 1px 2px 6px 2px #F3F2EB;
}
.quizListCardtitle {

}
.qListTitleCard {
	  font-size: 25px;
  color: black;
  border: 0px solid #F3F2EB;
  border-radius: 6px 6px 0px 0px;
  margin-top: 0px;
  width: 100%;
  position: absolute;
  height: 40px;
  font-family: Mali-Medium;
  text-shadow: 4px 4px 4px #aaa;
  background-color: #F3F2EB;
  word-wrap: break-word;
  padding-top: 10px;
}
.quizDone {
  background-color: red;
}
.scoreDone {
  height: 50px;
  width: 50px;
  font-size: 15px;
  border-radius: 0px 6px 0px 0px;
  background-color: blue;
  position: absolute;
  top:0px;
  right: 0px;
}
.tooltiptext {
    visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 20px;
 top: 50px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
.quizDone:hover .tooltiptext {
  visibility: visible;
}