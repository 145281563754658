.active {
	color:black !important;
	background-color:transparent;
}
.myFlexContainer {
	display:flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	margin-top: 40px;
	margin-bottom: 80px;
	background-color: #000000;
	opacity: 0.7;
}
.myFlexItem {
	margin: 10px;
}
.bCrumb{
	margin-top: 30px;
}
.verse p {
    font-size: 10px;
    color:#CCEEFF;
}
#leftMenu {
    height: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
}
#BibleMenu {
	padding-top: 20px;
}
#BibleMenuRight {
    float: right;
}
.myGrid {
    display: grid !important;
    grid-template-columns: 250px 1fr;
}
.myContain {
        grid-column-start: 2;
    grid-column-end: 3;
}
.bookCustomScroll {
	height:  100vh;
	flex-basis: 150px;
}
.bookCustomScroll2 {
	height:  100vh;
	flex-basis: 70px;
}
.myNotes {
	height:  100vh;
	flex-basis: 250px;
}
.myFlexReader {
	text-align: Left;
	padding-left: 30px;
}


.searchroot {
    padding: '2px 4px';
    display: 'flex';
    text-align: center;
    width: 100;
}
.searchinput {
	margin-left: 8px;
    flex: 1;
}
.searchiconButton {
	padding: 10px;
}
.searchdivider {
	width: 1px;
    height: 28px;
    margin: 4px;
}
.bibleBooks {
	color:white !important;
	min-width: 150px;
}

.bibleChapter {
	color:white !important;
	min-width: 50px;
}

.bibleVerse {
	color:white !important;
}
.bibleBooks:hover {
	color:white !important;
	min-width: 150px;
	padding: 2px;
	border:1px solid gold;
	cursor: pointer;
}

.bibleChapter:hover {
	color:white !important;
	min-width: 50px;
	padding: 10px;
	border:1px solid gold;
	cursor: pointer;
}

.bibleVerse:hover {
	color:gold !important;
	cursor: pointer;
}
.highl {
	background-color: teal;
}
@media only screen and (max-width: 800px) {
	#BibleMenu {
		margin-top: 30px;
	}
}
#bookselect{
	font-size: 25px;
}
#chapterselect{
	font-size: 25px;
}
#divBible {
	max-width: 800px;
}
#btPrev {
	position: fixed;
	margin-top: 50vh;
}
#btNext {
	position: fixed;
	margin-top: 50vh;
}
#divbtPrev {
	padding-right: 50px;
}
#divbtNext {
	padding-right: 50px;
}
#mySearchRoot {
	width: 100%;
	
}
#mySearchPaper {
	background-color: black;
}
#mySearchInput{
	color:#ffa000;
	font-size: 20px;
}
.verseResult {
	color:#fff8e1 !important;
	cursor: pointer;
}