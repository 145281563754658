.LoginPage {
	
}
.cardOpacity{
	opacity: 0.8;
}
.PaperOpacity{
	margin-top: 100px;
}
.ContainerCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    min-height: 80vh;
}